html {
  overflow-y: scroll;
  margin: 0;
}

.desktop-hide{
  display: none;
}
li {
  list-style: none;
}
a{
  text-decoration: none;
}
body {
  margin: 0;
  padding-right: 0px !important;
  padding-left: 0px !important;
  max-height: 100vh;
  font-family: "Poppins", sans-serif;
}

.bg-purple{
  background-color: #800080;
}

.bg-navy{
  background-color: #000080;
}
 

.select__control {
     min-height: 50px!important;
}

.select__menu-list{
  height: 200px!important;
  overflow-y: scroll!important;
  width: 100%;
}



 .form-control {
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
          border-radius: 0;
  border-style: solid;
    padding: 0.375rem 1rem;
    height:50px;
  font-size: 14px;
  color: #000;
  background-color: #ffffff;
  border-width: 1px;
}


  .form-control.form-control-clicked {
    background-color: #cfe2ff;
    color: #000; }
  .form-control:focus {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    color: #000;
    background-color: #ffffff; }
  .form-control.form-control-lg {
    height: 50px;
    padding: 0.625rem 1rem;
    font-size: 16px; }
  .form-control.form-control-sm {
    height: 32px;
    padding: 0.375rem 0.5rem;
    font-size: 12px;
    border-radius: 0.375rem; }

    .table {
  font-size: 14px; }

  textarea.form-control{

    height: 150px;
  }



  #app_side_menu
    {
      position: fixed;
        width:24%;
        float:left;
        background-color: #fff;
        height: 100vh;
        overflow-y: scroll;

    }

#app_side_menu::-webkit-scrollbar {
    width: 5px;
}

#app_side_menu::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#app_side_menu::-webkit-scrollbar-thumb {
    background: #bec4c4;
}

#app_side_menu::-webkit-scrollbar-track {
    background: #f1f1f1;
}

    .nav-cat-icon{
      margin-top: 12px;
    }

    .app-side-menu{
    position: relative;
      padding-left: 0;
    }
  
    .app-side-menu li
    {
      list-style: none;
/*    font-size: 12px;*/
    }
  
  .app-side-menu > li:hover
    {
        opacity: 1;
        filter: alpha(opacity=100);
    }
  
  .app-side-menu li:hover
    {
        background-color:whitesmoke;
    }
  
    .app-side-menu li a
    {
      text-decoration: none;
    display: block;
        min-width: 150px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    color:#000;
    }
    
  .app-side-menu li:hover a
    {
        color:#000;
    
    }



.app-round{
  border-radius: 30px!important;
}
.app-rounded{
    border-radius: 0.375rem;
}
.app-bold{
  font-weight: bold!important;
}
.app-bolder{
  font-weight: bolder!important;
}

.app-header-logo{
width: 50px!important;
height: 50px!important;
}

/*mobile*/

  @media screen and (max-width: 748px){

        .desktop-hide{
  display: block;
}

    .app-mobile-font{

  font-size: 12px!important;
}

    .product-img{
  object-fit: contain;
  height: 100px;
}

.page-content-wrapper {

    margin-top: 70px!important;
}


    .sell-btn{

  font-size: 14px;
    }

    .app-nav-text{
  font-size: 12px;

    }

    .app-home-cats{

  display: none;
}

#footerNav{
  display: block;
  
}

    .desktop-hide{
  display: block;
}

    .mobile-hide{

   display: none!important;
 }

    .nav-tabs .nav-item button{
  font-size: 12px;
}

    .mobile-top-ad{
      display: block;
    }

    .desktop-top-ad{
      display: none;
    }

    #app_side_menu{

  display: none;
}

 .mobile-text-hide{

   display: none;
 }

 .app-footer-icon{

  font-size: 18px!important;
}
 }